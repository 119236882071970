const serverUrl = "/api/index.php";

export const handleLogin = (email,password) => {
    return fetch(`${serverUrl}/login`,{
        method: "POST",
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        body: JSON.stringify({
            email,
            password
        })
    })
}

export const handleLoadBlogs = () => {
    return fetch(`${serverUrl}/blogs`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Accept": "application/json",
        }
    })
} 