
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapLocation, faPhone } from "@fortawesome/free-solid-svg-icons";

export const AppFooter = () => {
    return (
        <footer>
                <div className="footer-container">
                    <div className="c-info">
                        <div className="c-name footer-name">
                            Rand<img src="./logo.jpg" alt="app-logo" className="c-logo" />mwalk Tech
                        </div>
                        <div className="c-dets">
                            <div className="dets-caption">
                                Your innovative tech solution
                            </div>
                           <div className="dets-points">
                               <FontAwesomeIcon icon={faEnvelope} />
                               <span className="det-point">randomwalktech@gmail.com</span>
                           </div>
                           <div className="dets-points">
                               <FontAwesomeIcon icon={faMapLocation} />
                               <span className="det-point">Bamenda Comercial Avenue</span>
                           </div>
                           <div className="dets-points">
                               <FontAwesomeIcon icon={faPhone} />
                               <span className="det-point">+237678921882</span>
                           </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
}