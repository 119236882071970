import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./App.css";
import "./styles/nav.css";
import "./styles/index.css";
import "./styles/header.css";
import "./styles/footer.css";
import "./styles/forms.css";

import { HomePage } from './features/homepage';
import { Projects } from './features/projects';
import { LoginPage } from './features/login';
// import { SlideAnimation } from './components/display-components/slide-animation';




//import { socketInitialize } from './helpers/socketio';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/projects' element= {<Projects />} />
        <Route path='/login' element= {<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
