
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";

export const AppHeader = ({ clickAction, showNav }) => {
    
    return (
        <header>
                <div className="c-name">
                    Rand<img src="./logo.jpg" alt="app-logo" className="c-logo" />mwalk Tech
                </div>
                <div className={classNames({
                    "nav-show-button": true,
                })}>
                    <button 
                        className="nav-bar"
                        onClick={clickAction}
                    >
                        <FontAwesomeIcon 
                            icon={showNav ? faClose : faBars} 
                            color="#ffffff"
                        />
                    </button>
                </div>
                <div className="auth-box">
                    <button className="admin-auth" onClick={() => window.location.href = "/login"}>
                        Login
                    </button>
                </div>
            </header>
    );

}