import React, { useState } from "react"; 

import { AppHeader } from "../components/page-components/header";
import { LeftNav } from "../components/page-components/left-nav";

import "../styles/projects.css";
import { handleLoadBlogs } from "../services/authentication.services";

export const Projects = () => {

    const [showNav, setShowNav] = useState(false);
    const [menu, setMenu] = useState(false);

    const menuClick = ()=> {
        setShowNav(!showNav);
        setMenu(true);
    }

    const getBlogs = async () => {
       try {
            const response = await handleLoadBlogs();
            const res = await response.json();

            console.log(res);
       } catch (e) {
            console.log({error: e});
       }
    }

    React.useEffect(() => {
      getBlogs()
    },[]);

    return (
         <main className='body-wrapper'>
            <AppHeader 
              clickAction={() => menuClick()} 
              showNav={showNav}
            />
            <LeftNav 
                  menu={menu} 
                  showNav={showNav}
            />
            <div className="app-main" style={{marginTop: 56}}>
               
                <div className="right-section">
                   <h1 className="project-header">Our Projects</h1>
                   <div className="project-links">
                      <button 
                        className="link-button"
                        onClick={() => window.location.href = "/zoomanimation"}
                      >
                        Zoom Animation
                      </button>
                      <button 
                        className="link-button"
                        onClick={() => window.location.href = "/slideanimation"}
                      >
                        Slide Animation
                      </button>

                      <button 
                        className="link-button"
                        onClick={() => getBlogs()}
                      >
                        Run post
                      </button>
                      
                   </div>
                </div>
            </div>
         </main>
    );
}