
import { faCode, faLaptopCode, faMobile, faMobileScreenButton,  faPaintBrush, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faLaravel, faReact, faNode } from "@fortawesome/free-brands-svg-icons";



export const projectButtons = [
    {
        caption: "Websites/ web apps",
        icon: faReact,
        uri: "/projects"
    },
    {
        caption: "Node js APIs",
        icon: faNode,
        uri: "/projects/node"
    },
    {
        caption: "Laravel APIs",
        icon: faLaravel,
        uri: "/projects"
    },
    {
        caption: "Mobile Apps",
        icon: faMobile,
        uri: "/projects/react-native"
    }
]


export const serviceButtons = [
    {
        caption: "Web development",
        icon: faCode,
        uri: "/projects"
    },
    {
        caption: "Mobile development",
        icon: faMobileScreenButton,
        uri: "/projects"
    },
    {
        caption: "Graphics design",
        icon: faPaintBrush,
        uri: "/projects"
    },
    {
        caption: "Software development",
        icon: faLaptopCode,
        uri: "/projects"
    },
    {
        caption: "Cyber Security",
        icon: faUserSecret,
        uri: "/projects"
    }
    
]