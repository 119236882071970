import  { useEffect, useState } from "react";
import classNames from "classnames";
import { FormInputBox } from "../components/form-components/input-box";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import { handleLogin } from "../services/authentication.services";

export const LoginPage = () => {

    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [email,setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const authenticate = async (email,password) => {
        try {
            const resp = await handleLogin(email, password);
            const data = await resp.text();
            console.log(data);
        } catch (e) {
            console.log({error: `Operation failed! ${e}`});
        }
    }

    const handleEmail = (e) => {
        let val = e.target.value;
        if (val === undefined || val === null || val === "") {
            setEmailError(true);
        }else{
            setEmail(val);
            setEmailError(false);
        }
    }

    const handlePassword = (e) => {
        let val = e.target.value;
        if (val === undefined || val === null || val === "") {
            setPasswordError(true);
        }else{
            setPassword(val);
            setPasswordError(false);
        }
    }

    useEffect(() => {
        if (emailError || passwordError) {
            setError(true);
        }else{
            setError(false);
        }
    }, [emailError, passwordError])

    return (
         <main className='body-wrapper'>
            <div className="app-main">
                <div className="form-container">
                    <form className="auth-form">
                        <FormInputBox
                            icon={faEnvelope}
                            id= "email"
                            type="email"
                            required={true}
                            placeholder="e.g. myemail@provider.com"
                            error={emailError}
                            onChangeValue={(e) => handleEmail(e)}
                            autoComplete="email"
                        />
                        <FormInputBox
                            icon={faLock}
                            id="password"
                            type="password"
                            required={true}
                            placeholder="Type your password here"
                            error={passwordError}
                            onChangeValue={(e) => handlePassword(e)}
                            autoComplete=""
                        />
                        <button 
                            className={classNames({
                                "form-submit": true,
                                "input-error": error 
                            })}
                            onClick={(e) => {
                                e.preventDefault();
                                authenticate(email,password)
                            }}
                            disabled={ error }
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
         </main>
    );
}