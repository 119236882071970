import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const NavButton = ({showmethod, visible, caption, data}) => {
    
    return (
        <button className={classNames({"nav-button": true, "colored-bottom": visible? true : false})} onClick={showmethod}>
            <span className="label-box">
                <span className={classNames({"button-label": true})}>
                    {caption}
                </span>
                <span className={classNames({"caret": true, "inverted": visible? true: false })}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            </span>
            <span 
                className={classNames({"sub-nav": true, "show-nav": visible? true: false})}
            >
                {data?.map((d, i) => {
                    return (
                        <span 
                            className="sub-nav-button" 
                            onClick={() => window.location.href = d.uri}
                            key={i}
                        >
                            <FontAwesomeIcon icon={d.icon} />
                            {'  '}
                            <span className="sub-nav-caption">{d.caption}</span>
                        </span>
                    );
                })}
            </span>
        </button>
    )
}