import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const FormInputBox = ({ icon, type, id, error, required, placeholder, onChangeValue, autoComplete }) => {
    return (
        <div className={classNames({
            "input-box": true,
            "input-error": error ? true : false
            })}>
            <span className="input-icon">
                <FontAwesomeIcon icon={icon} />
            </span>
            <input 
                type={type} 
                id={id} 
                placeholder={placeholder} 
                className="form-input"
                onChange={onChangeValue}
                autoComplete={autoComplete} 
            />
            <span 
                className={classNames({
                    "input-status": true,
                    "required": required
                })}
            >
                *
            </span>
        </div>
    );
}