import {  useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCheckCircle } from "@fortawesome/free-solid-svg-icons";


import { AppFooter } from "../components/page-components/footer";
import { AppHeader } from "../components/page-components/header";
import { LeftNav } from "../components/page-components/left-nav";



export const HomePage = () => {

    const [showNav, setShowNav] = useState(false);
    const [menu, setMenu] = useState(false);

    const menuClick = ()=> {
        setShowNav(!showNav);
        setMenu(true);
    }

    return (
         <main className='body-wrapper'>
            <AppHeader 
                clickAction={() => menuClick()} 
                showNav={showNav}
            />
            <LeftNav menu={menu} showNav={showNav} />
            <main>
                <div className="bg-image">
                    <img src="./web-dev.jpg" alt="bg" className="bg" />
                </div>
                <div className="page-text">
                    <div className="dots-container">
                        <img src="./20240412_133943.png" width="300px" alt="dots" className="dots" />
                    </div>
                    <div className="org-theme">
                        <div className="c-theme">Offering innovative tech solutions to</div>
                        <div className="solutions">
                            <div className="sol-unit">
                                <FontAwesomeIcon icon={faCheckCircle} /><span className="sol-text">Healthcare</span>
                            </div>
                            <div className="sol-unit">
                                <FontAwesomeIcon icon={faCheckCircle} /><span className="sol-text">Logistics</span>
                            </div>
                            <div className="sol-unit">
                                <FontAwesomeIcon icon={faCheckCircle} /><span className="sol-text">Education</span>
                            </div>
                            <div className="sol-unit">
                                <FontAwesomeIcon icon={faCheckCircle} /><span className="sol-text">Finace</span>
                            </div>
                            <div className="sol-unit">
                                <FontAwesomeIcon icon={faCheckCircle} /><span className="sol-text">E-commerce</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="solution-sector">
                  
                </div>
            </main>
            <AppFooter />
         </main>
    );
}