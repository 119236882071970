import { NavButton } from "./nav-button";
import classNames from "classnames";
import { useState } from "react";
import { projectButtons, serviceButtons } from "../../utils/nav-data";


export const LeftNav = ({showNav, menu}) => {


  const [showProj, setProj] = useState(false);
  const [showDevs, setDevs] = useState(false);
  const [showServ, setServ] = useState(false);
  const [contact, showContact] = useState(false);

    return (
        <nav 
                className={classNames({
                    "app-nav": true,
                    "slide-nav": showNav === true && menu ? true : false,
                    "hide-nav": showNav === false && menu ? true : false,
                })}
            >
                <div className="nav-box">
                    <NavButton 
                        showmethod={() => setProj(!showProj)}
                        visible={showProj}
                        caption="Projects"
                        data={projectButtons}
                    />
                   
                    <NavButton
                        showmethod={() => setDevs(!showDevs)}
                        visible={showDevs}
                        caption="Developers"
                    />
                    
                    <NavButton
                        showmethod={() => setServ(!showServ)}
                        visible={showServ}
                        caption="Services"
                        data={serviceButtons}
                    />
                    <button 
                        className={classNames({"nav-button": true, "colored-bottom": contact? true : false})}
                        onClick={() => showContact(!contact)}
                    >
                        Contat us
                    </button>
                </div>
            </nav>
    );
}